//import logo from './logo.svg';
import React from 'react';
import './App.css';
import Did from './components/did.js';



function Sales() {
    return (
        <div>
            <Did></Did>
        </div>
    );
}

export default Sales;