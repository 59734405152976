import { Configuration, OpenAIApi } from "openai";
import React, { useRef, useState, useEffect } from 'react';

const Did = (props, ref) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const messagesContainerRef = useRef(null);

    const handleSendMessage = (value) => {
        if (value.toString().trim === '') return;

        const text = value.toString();
        const newMessage = {
            content: text,
            isUser: true,
        };

        setMessages([...messages, newMessage]);
        setInputMessage('');
    };

    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);


    // end message

//    const OPENAI_API_KEY = "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"
    const KEY = "amFrZUBlbmdpbmVlcmVkaW5ub3ZhdGlvbmdyb3VwLmNvbQ:AXE1EQVMeoVlxpGyo2aAF"
    const URL = "https://api.d-id.com"
    const ELEVEN_API_KEY = "301c061476e675019e09cbda471c5619"
    const [icegatheringStatusLabelValue, setIcegatheringStatusLabel] = useState('Not Started');

    const [iceStatusLabelValue, setIceStatusLabel] = useState('Not Started');
    const [peerStatusLabelValue, setPeerStatusLabel] = useState('Not Started');
    const [signalingStatusLabelValue, setSignalingStatusLabel] = useState('Not Started');
    const [streamingStatusLabelValue, setStreamingStatusLabel] = useState('Not Started');

    const [peerConnectionValue, setPeerConectionValue] = useState(null);
    const [streamIdValue, setStreamId] = useState('');
    const [sessionIdValue, setSessionId] = useState('');
    const [sessionClientAnswerValue, setSessionClientAnswer] = useState('');

    const [historyValue, setHistoryValue] = useState('');
    var historyRef = useRef();

    let peerConnection;
    let streamId;
    let sessionId;
    let sessionClientAnswer;

    let statsIntervalId;
    let videoIsPlaying;
    let lastBytesReceived;

    const RTCPeerConnection = (
        window.RTCPeerConnection ||
        window.webkitRTCPeerConnection ||
        window.mozRTCPeerConnection
    ).bind(window);

    async function onIceGatheringStateChange() {
        setIcegatheringStatusLabel(peerConnection.iceGatheringState);
        // this.iceGatheringStatusLabel.className = 'iceGatheringState-' + peerConnection.iceGatheringState;
    }

    async function onIceCandidate(event) {
        console.log('onIceCandidate', event);
        if (event.candidate) {
            const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

            fetch(`${URL}/talks/streams/${streamId}/ice`, {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${KEY}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    candidate,
                    sdpMid,
                    sdpMLineIndex,
                    session_id: sessionId,
                }),
            });
        }
    }
    async function onIceConnectionStateChange() {
        setIceStatusLabel(peerConnection.iceConnectionState);

        if (peerConnection.iceConnectionState === 'failed' || peerConnection.iceConnectionState === 'closed') {
            stopAllStreams();
            closePC();
        }
    }
    async function onConnectionStateChange() {
        // not supported in firefox

        setPeerStatusLabel(peerConnection.connectionState);
        //this.peerStatusLabel.className = 'peerConnectionState-' + peerConnection.connectionState;
    }
    async function onSignalingStateChange() {
        setSignalingStatusLabel(peerConnection.signalingState);
        //this.signalingStatusLabel.innerText = peerConnection.signalingState;
        //this.signalingStatusLabel.className = 'signalingState-' + peerConnection.signalingState;
    }

    async function onVideoStatusChange(videoIsPlaying, stream) {
        let status;
        if (videoIsPlaying) {
            status = 'streaming';
            const remoteStream = stream;
            await setVideoElement(remoteStream);

        } else {
            status = 'empty';
            await playIdleVideo();
            //turn mic back on
           // await dictaphoneRef.current.listenContinuously();
        }
        setStreamingStatusLabel(status);
        //this.streamingStatusLabel.className = 'streamingState-' + status;
    }

    async function onTrack(event) {
        /**
         * The following code is designed to provide information about wether currently there is data
         * that's being streamed - It does so by periodically looking for changes in total stream data size
         *
         * This information in our case is used in order to show idle video while no talk is streaming.
         */

        if (!event.track) return;

        statsIntervalId = setInterval(async () => {
            const stats = await peerConnection.getStats(event.track);
            stats.forEach((report) => {
                if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
                    const videoStatusChanged = videoIsPlaying !== report.bytesReceived > lastBytesReceived;

                    if (videoStatusChanged) {
                        videoIsPlaying = report.bytesReceived > lastBytesReceived;
                        onVideoStatusChange(videoIsPlaying, event.streams[0]);
                    }
                    lastBytesReceived = report.bytesReceived;
                }
            });
        }, 500);
    }

    async function createPeerConnection(offer, iceServers) {
        if (!peerConnection) {
            peerConnection = new RTCPeerConnection({ iceServers });
            peerConnection.addEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
            peerConnection.addEventListener('icecandidate', onIceCandidate, true);
            peerConnection.addEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
            peerConnection.addEventListener('connectionstatechange', onConnectionStateChange, true);
            peerConnection.addEventListener('signalingstatechange', onSignalingStateChange, true);
            peerConnection.addEventListener('track', onTrack, true);
        }

        await peerConnection.setRemoteDescription(offer);
        console.log('set remote sdp OK');

        const sessionClientAnswer = await peerConnection.createAnswer();
        console.log('create local sdp OK');

        await peerConnection.setLocalDescription(sessionClientAnswer);
        console.log('set local sdp OK');

        setPeerConectionValue(peerConnection);

        return sessionClientAnswer;
    }

    function setVideoElement(stream) {
        if (!stream) return;

        const vid = document.getElementById("video");
        vid.srcObject = stream;
        vid.loop = false;

        // safari hotfix
        if (vid.paused) {
            vid
                .play()
                .then((_) => { })
                .catch((e) => { });
        }
    }

    function playIdleVideo() {
        const vid = document.getElementById("video");

        vid.srcObject = undefined;
        vid.src = '/idle.mp4';
        vid.loop = true;
        vid.play();
    }

    function stopAllStreams() {
        const vid = document.getElementById("video");

        if (vid.srcObject) {
            console.log('stopping video streams');
            vid.srcObject.getTracks().forEach((track) => track.stop());
            vid.srcObject = null;
        }
    }

    function closePC(pc = peerConnection) {
        if (!pc) return;
        console.log('stopping peer connection');
        pc.close();
        pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
        pc.removeEventListener('icecandidate', onIceCandidate, true);
        pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
        pc.removeEventListener('connectionstatechange', onConnectionStateChange, true);
        pc.removeEventListener('signalingstatechange', onSignalingStateChange, true);
        pc.removeEventListener('track', onTrack, true);
        clearInterval(statsIntervalId);
        setIcegatheringStatusLabel('');
        setSignalingStatusLabel('');
        setIceStatusLabel('');
        setPeerStatusLabel('');
        console.log('stopped peer connection');
        if (pc === peerConnection) {
            peerConnection = null;
        }
    }

    const maxRetryCount = 3;
    const maxDelaySec = 4;

    async function fetchWithRetries(url, options, retries = 1) {
        try {
            return await fetch(url, options);
        } catch (err) {
            if (retries <= maxRetryCount) {
                const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;

                await new Promise((resolve) => setTimeout(resolve, delay));

                console.log(`Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`);
                return fetchWithRetries(url, options, retries + 1);
            } else {
                throw new Error(`Max retries exceeded. error: ${err}`);
            }
        }
    }

    async function Connect() {
        console.log('connect pressed');
        console.log("Env URL " + URL);
        //function Connect() {
        if (peerConnection && peerConnection.connectionState === 'connected') {
            return;
        }

        stopAllStreams();
        closePC();

        const sessionResponse = await fetchWithRetries('https://api.d-id.com/talks/streams', {
            method: 'POST',
            headers: {
                Authorization: `Basic amFrZUBlbmdpbmVlcmVkaW5ub3ZhdGlvbmdyb3VwLmNvbQ:AXE1EQVMeoVlxpGyo2aAF`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                source_url: 'https://storage.googleapis.com/synodos-dev-public/jon.jpeg',
            }),
        });

        const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
        streamId = newStreamId;
        sessionId = newSessionId;
        setStreamId(streamId);
        setSessionId(sessionId);

        try {
            sessionClientAnswer = await createPeerConnection(offer, iceServers);
        } catch (e) {
            console.log('error during streaming setup', e);
            await stopAllStreams();
            await closePC();
            return;
        }
        console.log(`${URL}/talks/streams/${streamId}/sdp`);
        const sdpResponse = await fetch(`${URL}/talks/streams/${streamId}/sdp`, {
            method: 'POST',
            headers: {
                Authorization: `Basic ${KEY}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                answer: sessionClientAnswer,
                session_id: sessionId,
            }),
        });

    };

    async function Talk(value) {

        // connectionState not supported in firefox
        if (peerConnection == null) {
            peerConnection = peerConnectionValue;
        }
        if (streamId == null) {
            streamId = streamIdValue;

        }
        if (sessionId == null) {
            sessionId = sessionIdValue;
        }
        if (peerConnection?.signalingState === 'stable' || peerConnection?.iceConnectionState === 'connected') {
            const talkResponse = await fetchWithRetries(`${URL}/talks/streams/${streamId}`, {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${KEY}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    script: {
                        type: 'text',
                        input: value,
                        provider: {
                            type: "microsoft",
                            voice_id: "en-US-DavisNeural",
                            "voice_config": {
                                "style": "Friendly"
                            }
                        },
                        ssml: true
                    },
                    driver_url: 'bank://lively/',
                    config: {
                        stitch: true,
                        driver_expressions: {
                            expressions: [
                                {
                                    start_frame: 0,
                                    expression: "happy",
                                    intensity: 1
                                }
                            ]
                        }
                    },
                    session_id: sessionId,
                }),
            });
        }
    };

    async function Destroy() {

        await fetch("https://api.d-id.com/talks/streams/" + streamIdValue, {
            method: 'DELETE',
            headers: {
                Authorization: `Basic ${KEY}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id: sessionId }),
        });

        stopAllStreams();
        closePC();
    };


    /////


    const configuration = new Configuration({
        organization: "org-3fRISLSkmWNUwHEZ8KQsD9c8",
        apiKey: "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",
    });

    function UserSpeaking() {

        console.log("User Speaking made it to the app root!!!!!!!!");


    }

    async function getGPTResponse(value) {

        handleSendMessage(value);

        console.log("Sending to GPT");
        const gptResponse = await fetch('http://localhost:8080/fruit', {
            method: 'POST',
            headers: {
                //        Authorization: `Basic amFrZUBlbmdpbmVlcmVkaW5ub3ZhdGlvbmdyb3VwLmNvbQ:AXE1EQVMeoVlxpGyo2aAF`,
                'Content-Type': 'application/json'
            },
            body:
                JSON.stringify({
                    "prompt": value
                })

        })
        const resp = await gptResponse.text();
        console.log("GPT Response: " + resp);
        handleSendMessage(resp);
        //dictaphoneRef.current.resumeListening() //temporary
        await Talk(resp);  //renable when reading to whire up speaking

    }

    return (
        <div>
            TESTING !@#
            <button onClick={() => { Connect(); }}>Start Session</button> &nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={() => { Destroy(); }}>Stop Session</button>
            <video id="video" height="450px" ></video>
            <div className='session-connection-info'>
                <b>ICE gathering status:</b> <label id="ice-gathering-status-label">{icegatheringStatusLabelValue}</label><br />
                <b>ICE status:</b> <label id="ice-status-label" >{iceStatusLabelValue}</label><br />
                Peer connection status: <label id="peer-status-label">{peerStatusLabelValue}</label><br />
                Signaling status: <label id="signaling-status-label">{signalingStatusLabelValue}</label><br />
                Streaming status: <label id="streaming-status-label">{streamingStatusLabelValue}</label><br />
            </div>
        </div >
    );
};

export default React.forwardRef(Did);